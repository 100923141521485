// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-accountancy-js": () => import("./../../../src/pages/accountancy.js" /* webpackChunkName: "component---src-pages-accountancy-js" */),
  "component---src-pages-automotive-js": () => import("./../../../src/pages/automotive.js" /* webpackChunkName: "component---src-pages-automotive-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-acceptable-use-js": () => import("./../../../src/pages/legal/acceptable-use.js" /* webpackChunkName: "component---src-pages-legal-acceptable-use-js" */),
  "component---src-pages-legal-cookies-js": () => import("./../../../src/pages/legal/cookies.js" /* webpackChunkName: "component---src-pages-legal-cookies-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-refund-policy-js": () => import("./../../../src/pages/legal/refund-policy.js" /* webpackChunkName: "component---src-pages-legal-refund-policy-js" */),
  "component---src-pages-legal-terms-conditions-js": () => import("./../../../src/pages/legal/terms-conditions.js" /* webpackChunkName: "component---src-pages-legal-terms-conditions-js" */),
  "component---src-pages-legal-terms-of-use-js": () => import("./../../../src/pages/legal/terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

